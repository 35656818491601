<!-- Supplier -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
       <!--begin: Settings-->
       <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
          <!--begin: Wizard Body-->
          <div class="row justify-content-center">
             <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <el-form ref="form" :model="form" auto-complete="nope">
                   <!--begin: Wizard Step 1-->
                   <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">   
                    <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-user"></i> {{title_text}}</h3>                  
                      <div class="form-group">
                        <div class="col-sm-6">
                          <div tabindex="0" class="el-upload el-upload--text">
                            <button type="button" class="el-button el-button--primary el-button--small btn-button-upload">
                              <span>Select File</span>
                            </button>
                            <input type="file" name="file" accept=".csv" class="upload-btn" @change="loadCSV($event)">
                          </div>                          
                            <!-- <input type="file" id="csv_file" name="csv_file" class="form-control" @change="loadCSV($event)" > -->
                          <span class="el-upload__tip" slot="tip"><span class="text-red">*</span>Accept only .csv file</span>
                        </div>                        
                         <div class="col-sm-9 mt-5">
                           <span class="form-err" v-if="form.errors.has('customer')" >Upload Customer.csv file is required</span>                       
                            <span class="download_sample">                            
                            <download-csv                             
                              :data   = "json_data"
                              name    = "customer.csv">
                              <i class="flaticon-attachment icon-md"></i>                          
                              Download Sample CSV                           
                          </download-csv>
                            </span>
                         </div>
                      </div>                     
                        <div class="mt-4 text-white mb-5">
	                    	<div class="col-sm-12">
	                    		<el-button size="small" type="success" @click="uploadSupplier">IMPORT Customer</el-button>
	                    	</div>
	                    </div>                    
                      <div class="col-sm-12">
                        <table v-if="parse_csv" style="display: block;overflow-x: auto;white-space: nowrap;">
                          <thead>
                              <tr>
                                <th v-for="(key,k) in parse_header" :key="k"
                                    @click="sortBy(key)"
                                    :class="{ active: sortKey == key }">
                                    {{ key | capitalize }}
                                    <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                                    </span>
                                </th>
                              </tr>
                          </thead>
                          <tr v-for="(csv,c) in parse_csv" :key="c">
                              <td v-for="(key,j) in parse_header" :key="j">
                                {{csv[key]}}
                              </td>
                          </tr>
                        </table>
                      </div>                      
                  </div>
                  </el-form>
              </div>
           </div>
        </div>
     </div>
  </div>
</template>



<style>
.settingicon{font-size:20px;}
select.select2{opacity:1;}
.preview-container img{max-width:200px;}
.add-logo-btn {margin-left:40px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents';

export default {
  name: 'add-supplier',
    data() {
    return {
      loading:false,
      title_text: 'Import Customer',
      api:'customer/import',     
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      form : new Form({
        customer:null,       
         
      }),
     
        json_data: [
            {   
                'First Name': 'Anto',
                'Last Name': 'Sunil',      
                'Email': 'testsupplier@gmail.com',                
                'Mobile': '8956789045',
                'Phone': '04532523307',
                'fax':'066-3453453',
                'Store': 'Peel',
                "Company Name":"Niyyo",               
                'User Status': 'active',
                'Unit No ': '23',
                'Street': '7th street',
                'Province/State': 'manitoba',
                'City': 'flin flon',
                'Postal code': 'ER2342',
                'Register Date':'07-10-2020',
                'roles':'customer',
                'Part of Mailing List':'Yes',
                'Job Category':'Plumber',
                'Outside Sales':'No',
                'Billing First Name':'Anto',
                'Billing Last Name': 'Sunil',
                'Billing Unit No': '23',
                'Billing Street': '7th street',
                'Billing Province/State': 'manitoba',
                'Billing City': 'flin flon',
                'Billing Postal code': 'ER2342',
                'Billing Email': 'testsupplier@gmail.com',                
                'Billing Mobile': '8956789045',
                'Shipping First Name':'Anto',
                'Shipping Last Name': 'Sunil',
                'Shipping Unit No': '23',
                'Shipping Street': '7th street',
                'Shipping Province/State': 'manitoba',
                'Shipping City': 'flin flon',
                'Shipping Postal code': 'ER2342', 
                'Profile Image Url':'https://stagingurl.tk/cts/durham/wp-content/uploads/sites/2/2020/05/bet197.jpg'              
            },
            {   
                'First Name': 'Anto',
                'Last Name': 'Sunil',      
                'Email': 'testsupplier@gmail.com',                
                'Mobile': '8956789045',
                'Phone': '04532523307',
                'fax':'066-3453453',
                'Store': 'Peel',
                "Company Name":"Niyyo",               
                'User Status': 'active',
                'Unit No ': '23',
                'Street': '7th street',
                'Province/State': 'manitoba',
                'City': 'flin flon',
                'Postal code': 'ER2342',
                'Register Date':'07-10-2020',
                'roles':'customer',
                'Part of Mailing List':'Yes',
                'Job Category':'Builder',
                'Outside Sales':'No',
                'Billing First Name':'Anto',
                'Billing Last Name': 'Sunil',
                'Billing Unit No': '23',
                'Billing Street': '7th street',
                'Billing Province/State': 'manitoba',
                'Billing City': 'flin flon',
                'Billing Postal code': 'ER2342',
                'Shipping First Name':'Anto',
                'Shipping Last Name': 'Sunil',
                'Shipping Unit No': '23',
                'Shipping Street': '7th street',
                'Shipping Province/State': 'manitoba',
                'Shipping City': 'flin flon',
                'Shipping Postal code': 'ER2342',  
                'Profile Image Url':'https://stagingurl.tk/cts/durham/wp-content/uploads/sites/2/2020/05/bet197.jpg'            
            }
        ]
    }
  },
   filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
       { title: "Dashboard", route: "/dashboard" },
       { title: "Customers", route: "/customer/list" },
       { title: "Import Customer", },
    ]);
  },
  methods: {
     sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv){
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",") 
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      
      lines.map(function(line, indexLine){
        if (indexLine < 1) return // Jump header line        
        var obj = {}
        var currentline = line.split(",")        
        headers.map(function(header, indexHeader){
          obj[header] = currentline[indexHeader]
        })        
        result.push(obj)
      })
      
      result.pop() // remove the last item because undefined values
      
      return result // JavaScript object
    },
    loadCSV(e) {
      var vm = this      
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        this.form.customer = e.target.files[0]; 
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          
        };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    }, 
   uploadSupplier(){
       	  this.loading = true;
	    		this.form.post(this.api).then((response) => {          
		        if(response.status){           
              //this.$emit('close');
              //globalEvents.$emit('importCustomer', false)
              this.$showResponse('success', response.message);
              this.$router.push({path:'/customer/list'})
              this.loading=false;             
		        }		      
		      }).catch(error => {
                console.log(error);
                this.loading = false;
            });
    },
    
    closeForm() {
        if (!this.form.submiting) {
            globalEvents.$emit('SupplierCreated', false)
            this.$emit('close')
        }
    },

  },
  created(){
   
  }

}
</script>

<style scoped>

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.icon-md{
  color:#3699ff !important
}

.download_sample{
    color: #3699ff;
    cursor: pointer;
}
</style>

